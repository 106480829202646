import React from "react"

import Layout from "../components/layout"

export default function HomePage() {
  return (
    <Layout>
      <h1>Accueil</h1>
      <hr />
      <p>En construction... 🚀</p>
    </Layout>
  )
}
